import React from "react";
import theme from "theme";
import { Theme, Text, Em, Box, Hr, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Green Pitch Fields
			</title>
			<meta name={"description"} content={"Your local football haven"} />
			<meta property={"og:title"} content={"Green Pitch Fields"} />
			<meta property={"og:description"} content={"Your local football haven"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
		</Helmet>
		<Components.Header2 />
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="50%"
				padding="0px 40px 0px 15px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 60px 0px"
			>
				<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline1" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Core Services
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
					Green Pitch Fields offers a wide range of services designed to enrich your football experience. From impeccably maintained fields to community events, we are here to make sure your love for the game shines through in every match you play.
				</Text>
				<Text margin="auto 0px 0px 0px" color="--lightD2" font="--lead">
					<Em>
						To learn more about our services or to book a field, feel free to contact us. Our team is passionate about football and ready to help you make the most of your time on the field.
					</Em>
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="50%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Field Rental
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							Perfect for friends and family looking to enjoy a relaxed game of football. Our fields provide ideal conditions for fun and fitness.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Em>
								<Strong>
									Casual Kickabouts
								</Strong>
							</Em>
						</Text>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							Organize local tournaments or league matches on our professional-level fields. Experience the thrill of competition in a community-oriented atmosphere.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								<Em>
									Competitive Matches
								</Em>
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							Celebrate special events with a game of football. Whether it's a birthday, corporate event, or just a get-together, our fields are available for rent.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								<Em>
									Event Celebrations
								</Em>
							</Strong>
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Community Events
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							Participate in our regularly organized local tournaments. It's a great way to compete, meet other football enthusiasts, and hone your skills.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								<Em>
									Local Tournaments
								</Em>
							</Strong>
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead">
							While this is not our main focus, we occasionally hold clinics aimed at skill development for young players, nurturing the next generation of football talent.
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							<Strong>
								<Em>
									Football Clinics
								</Em>
							</Strong>
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px" />
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Features
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Well-maintained fields
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Accessibility for all
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Equipment rental
						</Text>
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Locker rooms and showers
						</Text>
					</Box>
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px" />
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});